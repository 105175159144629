<template>
  <tx-dialog
    v-model="visible" :title="actionType === 1 ? t('requests.approveRequest') : t('requests.rejectRequest')"
    show-ok-cancel :ok-state="v$.$invalid ? 'disabled' : loading ? 'loading' : 'enabled'" :confirm-text="actionType === 1 ? 'requests.approve' : 'requests.reject'"
    @click="doCancel" @ok="onApproveReject"
  >
    <div class="w-full h-full">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
      <!-- FORM -->
      <div class="w-full h-full">
        <div v-for="attribute in visibleAttributes" :key="attribute.SystemName" class="mb-4">
          <attribute-editor
            v-model="formModel[attribute.SystemName]"
            :form="formModel"
            :articles="[]"
            :attribute="attribute"
            :disabled="attribute.ReadOnly"
            :required="attribute.IsRequired"
          />
        </div>
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { computed, reactive, ref } from 'vue'
import { createI18nMessage, required } from '@vuelidate/validators'
import { approveRequests, getRequestReasons, rejectRequests } from '@/api/t1/request'
import { useUserStore } from '@/store/userData'
import { appConstants, requestConstants } from '@/models/constants'
import { AttributeType } from '@/models/catalogAttribute'
import type { RequestModel, RequestReasonsModel, UpdateRequestStateModel } from '@/api/t1/model/requestModel'
import TxAlert from '@/shared/components/TxAlert.vue'
import AttributeEditor from '@/shared/components/AttributeEditor.vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import useErrorMessage from '@/shared/composables/errorMessage'
import utils from '@/services/utils'

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'approveReject'): void
}>()

const { t } = useI18n()
const withI18nMessage = createI18nMessage({ t })
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()

const loading = ref<boolean>(false)
const visible = ref<boolean>(false)
const requestReasons = ref<RequestReasonsModel[]>([])
const formModel = reactive<Record<string, any>>({})
const actionType = ref(0)
let selectedRequests: RequestModel[] = []

const visibleAttributes = computed(() => {
  const visibleAttributes: IMyAttribute[] = []
  const commentField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
    DisplayName: t('requests.comments'),
    IsRequired: (actionType.value !== 1),
    AttributeType: AttributeType.Nvarchar,
    SystemName: 'Comment',
    ReadOnly: false,
  })
  const requestReasonField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
    SystemName: 'ReasonId',
    DisplayName: t('requests.reasons'),
    Creatable: true,
    ReadOnly: false,
    IsRequired: true,
    DropDownData: requestReasons.value,
    DropDownValueDisplayProp: 'Reason',
    DropDownValueProp: 'Reason',
  })
  visibleAttributes.push(commentField)
  if (requestReasons.value.length) {
    visibleAttributes.push(requestReasonField)
  }
  return visibleAttributes
})

const rules = computed(() => {
  const rules: Record<string, any> = {}
  visibleAttributes.value.forEach((visibleAttribute) => {
    if (visibleAttribute.IsRequired) {
      rules[visibleAttribute.SystemName] = { required: withI18nMessage(required) }
    }
  })
  return rules
})

const v$ = useVuelidate(rules, formModel)

async function showDialog(selectedReqs, type: number) {
  reset()
  actionType.value = type
  selectedRequests = selectedReqs
  if (userStore.activeCatalog && selectedRequests.length) {
    loading.value = true
    getRequestReasons(userStore.activeCatalog.CatalogCode)
      .then((res) => {
        // use the first request to fetch the request reasons
        let requestType = utils.isDefined(requestConstants.requestTypes[selectedRequests[0].RequestType])
          ? requestConstants.requestTypes[selectedRequests[0].RequestType].key
          : selectedRequests[0].RequestSource === requestConstants.requestSources.similarStyle ? 'SimilarStyle' : 'AddColorway'
        if (selectedRequests[0].RequestType === requestConstants.requestTypes.EditAttribute.key) {
          requestType = Object.keys(selectedRequests[0].Content)[0]
        }

        requestReasons.value = res.data.filter(reason => reason.Status === 1 && reason.Type === type && reason.RequestType === requestType)
      })
      .catch(e => console.error(e))
      .finally(() => {
        loading.value = false
      })
  }
}

async function onApproveReject() {
  try {
    errorMessage.value = ''
    loading.value = true

    const processedIds = new Set()
    const requestObjectArray: UpdateRequestStateModel[] = []

    selectedRequests.forEach((selectedRequest) => {
      if (!processedIds.has(selectedRequest.Id)) {
        requestObjectArray.push({
          Id: selectedRequest.Id,
          Reason: formModel.ReasonId || '',
          Comment: formModel.Comment || '',
        })
        processedIds.add(selectedRequest.Id)
      }
    })

    if (actionType.value === 1) {
      await approveRequests(userStore.activeCatalog!.CatalogCode, requestObjectArray)
    }
    else {
      await rejectRequests(userStore.activeCatalog!.CatalogCode, requestObjectArray)
    }

    await userStore.doLoadData(['Requests'])
    emit('approveReject')
    visible.value = false
  }
  catch (error: any) {
    if (error.response && error.response.data && error.response.data.length) {
      const alreadyApproveOrReject = error.response.data.filter(e => e.ErrorCode === '26335470' || e.ErrorCode === '26335471')
      if (alreadyApproveOrReject && alreadyApproveOrReject.length) {
        errorMessage.value = (actionType.value === 1) ? t('requests.requestIsAlreadyApproved', selectedRequests.length) : t('requests.requestIsAlreadyRejected', selectedRequests.length)
      }
      else {
        errorMessage.value = error.response.data.map(e => e.ErrorMessage).join(', ')
      }
    }
    else {
      errorMessage.value = t('general.unexpectedError')
    }
    console.error(error)
  }
  finally {
    loading.value = false
  }
}

function doCancel() {
  visible.value = false
  emit('cancel')
}

function reset() {
  requestReasons.value = []
  utils.resetReactiveObject(formModel)
  loading.value = false
  visible.value = true
  errorMessage.value = ''
}

defineExpose({
  showDialog,
  closeDialog: doCancel,
})
</script>
