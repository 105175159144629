import { getModelArticles } from '@/api/t1/article'
import appConfig from '@/services/appConfig'
import useEventsBus from '@/shared/composables/eventBus'
import { useUserStore } from '@/store/userData'

export default function useArticleLocalDataUpdater() {
  const userStore = useUserStore()
  const { emitter } = useEventsBus()

  async function refreshLocalArticlesData(IsSeasonless: boolean, modelId: number, articleId?: number, notify = true) {
    if (userStore.activeCatalog) {
      const catalogCodes = IsSeasonless ? userStore.activeCatalog._AllRelatedCatalogCodes : [userStore.activeCatalog.CatalogCode]

      await getModelArticles(userStore.activeCatalog.AccountId, userStore.activeCatalog.AttributeGroupId, modelId, catalogCodes, articleId)
        .then(async (res) => {
          if (res.data && res.data.length > 0) {
            await appConfig.DB!.bulkUpdateModelArticlesFromAPI(userStore.activeCatalog!, userStore.linkedCatalogDetails, res.data)
            if (notify) {
              emitter('catalogDataUpdated', { source: 'Local' })
            }
          }
        })
        .catch((e) => {
          console.error(`Unable to update article data (${catalogCodes}, ${modelId}, ${articleId})`, e)
          throw e
        })
    }
  }

  return {
    refreshLocalArticlesData,
  }
}
