<template>
  <tx-dialog v-model="visible" :title="props.titleAttribute && data[props.titleAttribute] ? data[props.titleAttribute] : t('general.details')" width="70%" height="90%">
    <div class="flex max-h-full overflow-x-hidden w-full flex-row flex-wrap overflow-y-scroll justify-start p-2 select-text">
      <div v-for="field in props.fields" :key="field.property" class="w-1/2 h-14">
        <div>
          <div class="font-bold">
            {{ field.title }}
          </div>
          <tx-data-table-field-viewer
            class="left" width="200px" :field="field" :column-data="data" :value="get(data, field.property, null)"
            :lookup-data="lookupData[field.property]"
          />
        </div>
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref, watch } from 'vue'
import { get } from 'lodash-es'
import TxDataTableFieldViewer from './TxDataTableFieldViewer.vue'
import type { ITxDataTableColumn } from './TxDataTable.types'
import TxDialog from '@/shared/components/TxDialog.vue'
import useMasterDataLookup from '@/modules/admin/composables/masterDataLookup'
import { useUserStore } from '@/store/userData'

const props = withDefaults(defineProps<{
  fields: ITxDataTableColumn[]
  titleAttribute?: string
}>(), {})

const { t } = useI18n()
const { loadLookupForTable } = useMasterDataLookup()
const userStore = useUserStore()

const visible = ref(false)
let data: any = {}
const lookupData = ref<Record<string, any>>({})

function showDialog(rowData: any) {
  visible.value = true
  data = rowData
}
watch(() => props.fields, async () => {
  if (userStore.activeCatalog) {
    lookupData.value = await loadLookupForTable(props.fields, userStore.activeCatalog)
  }
})

defineExpose({
  showDialog,
})
</script>
