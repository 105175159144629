<template>
  <div class="container relative flex flex-col w-full h-full overflow-hidden">
    <!-- HEADER -->
    <tx-form-header class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header" :title="t('assignColor.title')" :subtitle="t('assignColor.header')" :show-header="showHeader" />

    <!-- BODY -->
    <div class="px-10 mt-4 alerts">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
    </div>
    <!-- FORM -->
    <div class="relative flex-auto mt-4 body">
      <!-- LOADER -->
      <div v-show="loading">
        <loader style="z-index: 1801;" />
        <div class="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-transparent opacity-50" />
      </div>
      <div class="grid w-full grid-cols-2 px-10 gap-x-6">
        <div v-for="attribute in allowedAttributes" :key="attribute.SystemName" class="mb-5 last-of-type:mb-4">
          <attribute-editor
            v-if="attribute.SystemName !== 'ArticleNumber'" v-model="formModel[attribute.SystemName]" :attribute="attribute"
            :required="attribute.IsRequired" :form="formModel" :articles="[props.contextArticle]" @change="onAttributeChanged(attribute.SystemName)"
          />
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <tx-form-footer
      class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
      :primary-text="t('general.assign')" :primary-disabled="v$.$invalid" :secondary-disabled="loading"
      @primary-click="onUpdate" @secondary-click="onCancel"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, onMounted, reactive, ref } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import useArticleLocalDataUpdater from '../composables/articleLocalDataUpdater'
import AttributeEditor from '@/shared/components/AttributeEditor.vue'
import Loader from '@/shared/components/Loader.vue'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import type MyArticle from '@/models/myArticle'
import { updateArticle } from '@/api/t1/article'
import { appConstants } from '@/models/constants'
import utils from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import TxFormHeader from '@/shared/components/forms/TxFormHeader.vue'

interface IProps {
  showColorway?: boolean
  showHeader?: boolean
  contextArticle: MyArticle
}

const props = withDefaults(defineProps<IProps>(), { showColorway: false, showHeader: true })

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated', articles: MyArticle[]): void
  (e: 'back'): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { refreshLocalArticlesData } = useArticleLocalDataUpdater()
const { errorMessage, hasError } = useErrorMessage()

const loading = ref<boolean>(false)
const formModel = reactive<Record<string, any>>({})
const dirtyAttributes = ref<string[]>([]) // array to store dirty attributes

const allowedAttributes = computed(() => {
  const result: IMyAttribute[] = []
  if (userStore.activeCatalog && userStore.myAttributes) {
    result.push(appConstants.staticAttributes.ColorId)
    const myAttributes = userStore.myAttributes
    userStore.activeCatalog.Config.AssignColorRequiredSeasonlessArticleAttributes.forEach((itm) => {
      const attribute = myAttributes[itm]
      if (attribute.IsSeasonless) {
        result.push(attribute)
      }
    })
  }
  return result
})
function init() {
  if (allowedAttributes.value && allowedAttributes.value.length && props.contextArticle) {
    allowedAttributes.value.forEach((attribute) => {
      formModel[attribute.SystemName] = props.contextArticle[attribute.SystemName] ? props.contextArticle[attribute.SystemName] : null
    })
  }
}

const rules = computed(() => {
  const result: Record<string, any> = {}
  allowedAttributes.value.forEach((attribute) => {
    result[attribute.SystemName] = {}
    if (attribute.IsRequired) {
      result[attribute.SystemName].required = helpers.withMessage(t('validations.required', { property: attribute.DisplayName }), required)
    }
  })
  return result
})

const v$ = useVuelidate(rules, formModel)

function onCancel() {
  emit('cancel')
}

async function onUpdate() {
  if (!(await v$.value.$validate())) {
    errorMessage.value = t('validations.formInvalid')
    return
  }
  const article = props.contextArticle
  loading.value = true
  const requestObj: Record<string, any> = {}

  try {
    allowedAttributes.value.forEach((attribute) => {
      if (formModel.hasOwnProperty(attribute.SystemName) && dirtyAttributes.value.includes(attribute.SystemName)) {
        const attributeValue = utils.getArticleAttributeTypeSpecificValue(attribute, formModel[attribute.SystemName])
        article[attribute.SystemName] = attributeValue
        requestObj[attribute.SystemName] = attributeValue
      }
    })
    requestObj.ArticleName = article.ArticleName
    requestObj.ModelNumber = article.ModelNumber
    await updateArticle(userStore.activeCatalog!.CatalogCode, article.Id, requestObj, true)
    await refreshLocalArticlesData(false, article.ModelId, article.Id)
    emit('updated', [props.contextArticle])
  }
  catch (error) {
    errorMessage.value = t('general.unexpectedError')
  }
  finally {
    loading.value = false
  }
}

function onAttributeChanged(attributeSystemName: string) {
  dirtyAttributes.value.push(attributeSystemName)
}

onMounted(() => {
  init()
})
</script>
