<template>
  <div v-show="visible" class="flex flex-col w-full h-full">
    <!-- SUB HEADER -->
    <div class="flex-shrink-0 text-center">
      <!-- SUB TITLE -->
      <div class="text-base text-gray-600 mb-7">
        {{ t('modelCreate.subTitle.modelForm') }}
      </div>
      <!-- NOTES -->
      <div class="text-sm text-gray-600" :class="{ 'mb-8': headerNotes.length }">
        {{ headerNotes }}
      </div>
    </div>

    <!-- BODY -->
    <div class="flex-grow mx-24 my-3 overflow-x-hidden overflow-y-auto">
      <div class="flex flex-row flex-wrap">
        <div v-for="(attribute, index) in refVisibleAttributes" :key="attribute.SystemName" class="px-5 basis-1/4 mb-7">
          <attribute-editor
            v-model="modelFormModel[attribute.SystemName]"
            :form="modelFormModel"
            :articles="targetedArticles"
            :attribute="attribute"
            :disabled="attribute.ReadOnly || disabledFieldsBasedOnData[attribute.SystemName]"
            :required="(attribute.SystemName.toString() in overwriteRequiredProperty ? overwriteRequiredProperty[attribute.SystemName] : attribute.IsRequired) || requiredFieldsBasedOnData[attribute.SystemName]"
            :errors="v$[attribute.SystemName].$errors"
            :show-external-change-management-info="true"
            :show-update-seasonless-attribute-warning="false"
            :tooltip-placement="index % 4 === 0 ? 'bottom' : 'left'"
            @blur="v$[attribute.SystemName].$touch"
            @reset-size-scale="resetSizeScaleAndSizes"
            @change="handleChange(attribute, $event)"
          />
        </div>
        <div v-if="props.createType === 'copyModel' && !isAddRequest" class="px-5 basis-1/4 mb-7">
          <form-editor
            v-if="props.createType === 'copyModel'"
            v-model="modelFormModel[copyModelCopiesAttribute.SystemName]"
            :attribute="copyModelCopiesAttribute"
            :form="modelFormModel"
            :errors="v$[copyModelCopiesAttribute.SystemName]?.$errors"
            :required="true"
            :show-label="true"
            :clearable="false"
            :external-message="modelFormModel[copyModelCopiesAttribute.SystemName] > 5 ? t('modelCreate.copiesSubTitle') : ''"
            @change="handleFormChange(copyModelCopiesAttribute.SystemName)"
          />
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="flex flex-row justify-end flex-shrink-0 mx-24 mb-16 flex-nowrap">
      <tx-button type="cancel" :text="t('general.cancel')" @click="onCancel" />
      <tx-button class="ml-5" type="confirm" :disabled="v$.$invalid" :text="t('general.next')" @click="nextStep" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import useVuelidate from '@vuelidate/core'
import { computed, reactive, ref, watch } from 'vue'
import { helpers, integer, maxLength, minValue, required } from '@vuelidate/validators'
import { uniqBy } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import AttributeEditor from '@/shared/components/AttributeEditor.vue'
import type CatalogDetails from '@/models/catalogDetails'
import type MyArticle from '@/models/myArticle'
import TxButton from '@/shared/components/TxButton.vue'
import useErrorMessage from '@/shared/composables/errorMessage'
import utils from '@/services/utils'
import { useArticleFormHelper } from '@/shared/composables/articleFormHelper'
import { useUserStore } from '@/store/userData'
import { AttributeType } from '@/models/catalogAttribute'
import { appConstants } from '@/models/constants'
import FormEditor from '@/shared/components/FormEditor.vue'

const props = defineProps<{
  createType: StyleCreateType
  formLayoutConfig?: Record<string, IStyleCreateFormLayoutConfig>
  showOnlySeasonalAttributes: boolean
  targetedArticles: MyArticle[]
  requestArticles?: MyArticle[]
  visible: boolean
  visibleAttributes: IMyAttribute[]
  isAddRequest: boolean
  linkCatalogIndexedByPriceNameToId?: Record<number, Record<string, number>>
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'next', payload: Record<string, any>): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage } = useErrorMessage()
const { prefillAttributeValue, validateAndRemoveInvalidAttributeValues } = useArticleFormHelper()

const modelFormModel = reactive<Record<string, any>>({})

const headerNotes = ref('')
let formInitialized = false
const disabledFieldsBasedOnData: Record<string, boolean> = reactive({})
const requiredFieldsBasedOnData: Record<string, boolean> = reactive({})
const overwriteRequiredProperty: Record<string, boolean> = reactive({})
const refVisibleAttributes = ref(props.visibleAttributes)
const copyModelCopiesAttribute: IFormEditorAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
  SystemName: 'ModelCopies',
  DisplayName: t('general.numberOfCopies'),
  Creatable: true,
  Editable: true,
  AttributeType: AttributeType.Int,
  ReadOnly: false,
  IsRequired: true,
  minValue: 1,
})

const validations = computed(() => {
  const result: Record<string, any> = {}
  refVisibleAttributes.value.forEach((attribute) => {
    result[attribute.SystemName] = {}
    if ((attribute.SystemName.toString() in overwriteRequiredProperty ? overwriteRequiredProperty[attribute.SystemName] : attribute.IsRequired) || requiredFieldsBasedOnData[attribute.SystemName]) {
      result[attribute.SystemName].required = helpers.withMessage(t('validations.required', { property: attribute.DisplayName }), required)
    }
    if (attribute.maxLength != null) {
      result[attribute.SystemName].maxLength = helpers.withMessage(t('validations.maxLength', { property: attribute.DisplayName, max: attribute.maxLength }), maxLength(attribute.maxLength))
    }
  })
  result[copyModelCopiesAttribute.SystemName] = {}
  result[copyModelCopiesAttribute.SystemName].required = helpers.withMessage(t('validations.required', { property: copyModelCopiesAttribute.DisplayName }), required)
  result[copyModelCopiesAttribute.SystemName].integer = helpers.withMessage(t('validations.invalidValue', { property: copyModelCopiesAttribute.DisplayName }), integer)
  result[copyModelCopiesAttribute.SystemName].minValue = helpers.withMessage(t('validations.minValue', { property: copyModelCopiesAttribute.DisplayName, min: 1 }), minValue(copyModelCopiesAttribute.minValue!))
  return result
})

const v$ = useVuelidate(validations, modelFormModel)

// WATCHERS
watch(() => props.visibleAttributes, (val) => {
  refVisibleAttributes.value = val
})

watch(() => props.createType, () => {
  formInitialized = false
})

watch(() => props.visible, (visible) => {
  if (visible && !formInitialized) {
    initForm()
    formInitialized = true
  }
}, { immediate: true })
// WATCHERS-ENDS

function initForm() {
  // for articles that does not belongs to this catalog match prices by price group name
  const indexedLinkedCatalogPricesByPriceGroupName = {}
  utils.resetReactiveObject(modelFormModel)
  modelFormModel[copyModelCopiesAttribute.SystemName] = 1

  if (props.requestArticles && props.requestArticles.length) {
    // modelFormModel[visibleAttribute.SystemName] = prefillAttributeValue(modelFormModel, props.requestArticles.length ? similarRequestArticles[0][visibleAttribute.SystemName] : undefined, visibleAttribute)
    refVisibleAttributes.value.forEach((visibleAttribute) => {
      // COPY DATA
      if (visibleAttribute.AttributeSource !== '_Prices') { // copy non price attributes
        modelFormModel[visibleAttribute.SystemName] = prefillAttributeValue(modelFormModel, props.requestArticles && props.requestArticles.length ? props.requestArticles[0][visibleAttribute.SystemName] : undefined, visibleAttribute)
      }
      else { // copy prices
        modelFormModel[visibleAttribute.SystemName] = props.requestArticles && props.requestArticles.length ? props.requestArticles[0]._Prices[visibleAttribute.SystemName]?.Price || 0 : 0
      }

      // DISABLED FIELD BASED ON DATA - disable ParentModelNumber field if value for parent_model_type value is "parent", for child it will be always disabled as the attribute ReadOnly will be true
      if (visibleAttribute.SystemName === 'parent_model_type') {
        disabledFieldsBasedOnData.ParentModelNumber = modelFormModel.parent_model_type?.toLowerCase() === 'parent'
        requiredFieldsBasedOnData.ParentModelNumber = modelFormModel.parent_model_type?.toLowerCase() !== 'parent'
      }
    })
    if (props.createType === 'copyModel') {
      modelFormModel._SourceModelName = props.requestArticles && props.requestArticles.length ? props.requestArticles[0]._SourceModelNumber : ''
      modelFormModel._SourceModelNumber = props.requestArticles && props.requestArticles.length ? props.requestArticles[0]._SourceModelNumber : ''
      modelFormModel.ModelName = props.requestArticles[0].ModelName
    }
    const dataForValidation = Object.assign({}, props.requestArticles && props.requestArticles.length ? props.requestArticles[0] : {}, modelFormModel)
    validateAndRemoveInvalidAttributeValues(refVisibleAttributes, modelFormModel, dataForValidation)
  }
  else {
    let linkedCatalogArticle = false
    if (props.targetedArticles && props.targetedArticles.length
      && props.targetedArticles[0].CatalogCode !== userStore.activeCatalog!.CatalogCode
      && userStore.linkedCatalogDetails[props.targetedArticles[0].CatalogCode]
    ) {
      linkedCatalogArticle = true
      // targeted articles are always 1 article in case of copyModel, childModel and carryoverModel, in case of carryoverArticles it will be multiple articles that belongs to one model from other seasons
      const linkedCatalogWithHighestSeasonalSequence = uniqBy(props.targetedArticles, 'CatalogCode').map(article => article.CatalogCode).reduce((acu: Array<CatalogDetails>, cur) => {
        if (userStore.linkedCatalogDetails[cur]) {
          acu.push(userStore.linkedCatalogDetails[cur])
        }
        return acu
      }, []).sort((a, b) => (b.SeasonalSequence || 0) - (a.SeasonalSequence || 0))[0]
      // for carryover might be linkedCatalogWithHigherSeasonalSequence is undefined as we do only have details of catalogs which in linkedCatalog list as 1,2 not 3,4
      if (linkedCatalogWithHighestSeasonalSequence) {
        for (const linkedCatalogPriceGroupId in props.targetedArticles[0]._Prices) {
          if (linkedCatalogWithHighestSeasonalSequence._IndexedCatalogPriceGroup.hasOwnProperty(linkedCatalogPriceGroupId)) {
          // index prices by price name
            const priceGroupName = linkedCatalogWithHighestSeasonalSequence._IndexedCatalogPriceGroup[linkedCatalogPriceGroupId].Name.toString().trim().toLowerCase()
            indexedLinkedCatalogPricesByPriceGroupName[priceGroupName] = props.targetedArticles[0]._Prices[linkedCatalogPriceGroupId]
          }
        }
      }
    }
    else {
      // for carryover article, if target article belongs to the 3rd or 4th linked catalog,then we don't have the liked catalog details
      // we have used the price name to id which is passed as prop ie. linkCatalogIndexedByPriceNameToId
      if (props.linkCatalogIndexedByPriceNameToId && props.linkCatalogIndexedByPriceNameToId[props.targetedArticles[0].CatalogCode]) {
        linkedCatalogArticle = true
        const priceIndexByName = props.linkCatalogIndexedByPriceNameToId[props.targetedArticles[0].CatalogCode]
        Object.keys(priceIndexByName).forEach((name) => {
          if (props.targetedArticles[0]._Prices[priceIndexByName[name]]) {
            indexedLinkedCatalogPricesByPriceGroupName[name] = props.targetedArticles[0]._Prices[priceIndexByName[name]]
          }
        })
      }
    }

    refVisibleAttributes.value.forEach((visibleAttribute) => {
      // ATTRIBUTE'S DEFAULT VALUE - set attribute's DefaultValue if model's attribute value is empty and cell is not disabled, keep parent_model_type empty for create child model
      if (
        (!utils.isDefined(modelFormModel[visibleAttribute.SystemName]) || !modelFormModel[visibleAttribute.SystemName].toString().length)
        && utils.isDefined(visibleAttribute.DefaultValue) && visibleAttribute.DefaultValue.toString().length && !visibleAttribute.ReadOnly
        && !(props.createType === 'childModel' && visibleAttribute.SystemName === 'parent_model_type')
      ) {
        modelFormModel[visibleAttribute.SystemName] = visibleAttribute.DefaultValue
      }

      // PRE POPULATE - always pre populate (if there are targeted articles) unless pre populate is false (for carryover model and carryover article it should copy only seasonal attributes)
      let prePopulate = false
      if (
        ((!visibleAttribute.IsSeasonless || (visibleAttribute.SystemName === 'SizeScale')) || props.createType === 'copyModel' || props.createType === 'childModel')
        && !(props.createType === 'childModel' && visibleAttribute.SystemName === 'parent_model_type') && !(props.createType === 'copyModel' && visibleAttribute.SystemName === 'SizeScaleId')
        && !(props.createType === 'copyModel' && visibleAttribute.SystemName === 'MasterSizeScaleId')
        && !(props.createType === 'copyModel' && (visibleAttribute.SystemName === 'parent_model_type' || visibleAttribute.SystemName === 'ParentModelNumber' || visibleAttribute.SystemName === 'ParentModelName' || visibleAttribute.SystemName === 'MasterModelId'))
        && (
          props.formLayoutConfig == null || !props.formLayoutConfig.hasOwnProperty(visibleAttribute.SystemName)
          || !props.formLayoutConfig[visibleAttribute.SystemName].hasOwnProperty('prePopulate') || props.formLayoutConfig[visibleAttribute.SystemName].prePopulate
        )
      ) {
        prePopulate = true
      }

      // COPY DATA
      if (visibleAttribute.AttributeSource !== '_Prices') { // copy non price attributes
        if (prePopulate) {
          // assign default value in case of undefined
          modelFormModel[visibleAttribute.SystemName] = prefillAttributeValue(modelFormModel, props.targetedArticles && props.targetedArticles.length ? props.targetedArticles[0][visibleAttribute.SystemName] : undefined, visibleAttribute)
        }
        else if (!modelFormModel.hasOwnProperty(visibleAttribute.SystemName)) {
          modelFormModel[visibleAttribute.SystemName] = visibleAttribute.AttributeType === AttributeType.Bool && visibleAttribute.IsRequired ? 'false' : undefined
        }
      }
      else { // copy prices
        modelFormModel[visibleAttribute.SystemName] = 0
        if (linkedCatalogArticle) {
          if (prePopulate) {
            const currentCatalogCurrentPriceGroupName = userStore.activeCatalog!._IndexedCatalogPriceGroup[visibleAttribute.SystemName].Name.toString().trim().toLowerCase()
            if (indexedLinkedCatalogPricesByPriceGroupName.hasOwnProperty(currentCatalogCurrentPriceGroupName)) {
              modelFormModel[visibleAttribute.SystemName] = indexedLinkedCatalogPricesByPriceGroupName[currentCatalogCurrentPriceGroupName].Price
            }
            else {
              modelFormModel[visibleAttribute.SystemName] = 0
            }
          }
        }
        else {
          modelFormModel[visibleAttribute.SystemName] = props.targetedArticles && props.targetedArticles.length ? props.targetedArticles[0]._Prices[visibleAttribute.SystemName]?.Price || 0 : 0
        }
      }

      // DISABLED FIELD BASED ON DATA - disable ParentModelNumber field if value for parent_model_type value is "parent", for child it will be always disabled as the attribute ReadOnly will be true
      if (visibleAttribute.SystemName === 'parent_model_type') {
        disabledFieldsBasedOnData.ParentModelNumber = modelFormModel.parent_model_type?.toLowerCase() === 'parent'
        requiredFieldsBasedOnData.ParentModelNumber = modelFormModel.parent_model_type?.toLowerCase() !== 'parent'
      }
    })
    if (props.createType === 'copyModel') {
      modelFormModel._SourceModelName = props.targetedArticles && props.targetedArticles.length ? props.targetedArticles[0].ModelName : ''
      modelFormModel._SourceModelNumber = props.targetedArticles && props.targetedArticles.length ? props.targetedArticles[0].ModelNumber : ''
      modelFormModel.ModelName = ''
    }
    else if (props.createType === 'childModel') {
      modelFormModel.ParentModelNumber = props.targetedArticles && props.targetedArticles.length ? props.targetedArticles[0].ModelNumber : undefined
      modelFormModel.ParentModelName = props.targetedArticles && props.targetedArticles.length ? props.targetedArticles[0].ModelName : undefined
      modelFormModel.MasterModelId = props.targetedArticles && props.targetedArticles.length ? props.targetedArticles[0].ModelId : undefined
    }
    const dataForValidation = Object.assign({}, props.targetedArticles && props.targetedArticles.length ? props.targetedArticles[0] : {}, modelFormModel)
    validateAndRemoveInvalidAttributeValues(refVisibleAttributes, modelFormModel, dataForValidation)
  }
}

function resetSizeScaleAndSizes() {
  modelFormModel.SizeScaleId = null
  modelFormModel.Sizes = ''
}

function handleChange(attribute, val) {
  // CHANGES IN PARENT MODEL TYPE
  if (v$.value && v$.value[attribute.SystemName]) {
    v$.value[attribute.SystemName].$touch()
  }
  requiredFieldsBasedOnData.ParentModelNumber = modelFormModel.parent_model_type?.toLowerCase() !== 'parent'
  if (props.createType !== 'childModel' && attribute.SystemName === 'parent_model_type') {
    if (utils.isDefined(modelFormModel.parent_model_type) && modelFormModel.parent_model_type.toLowerCase() === 'parent') {
      disabledFieldsBasedOnData.ParentModelNumber = true
      modelFormModel.ParentModelNumber = undefined
      modelFormModel.MasterModelId = undefined
      modelFormModel.ParentModelName = undefined
    }
    else {
      disabledFieldsBasedOnData.ParentModelNumber = false
      requiredFieldsBasedOnData.ParentModelNumber = true
    }
  }

  // CHANGES IN PARENT MODEL NUMBER
  if (attribute.SystemName === 'ParentModelNumber') {
    if (modelFormModel.ParentModelNumber == null) {
      modelFormModel.MasterModelId = undefined
      modelFormModel.ParentModelName = undefined
    }
    else {
      modelFormModel.MasterModelId = modelFormModel.ParentModelNumber.ModelId
      modelFormModel.ParentModelName = modelFormModel.ParentModelNumber.ModelName
      modelFormModel.ParentModelNumber = modelFormModel.ParentModelNumber.ModelNumber
    }
  }

  if (attribute.SystemName === 'SizeScaleId') {
    modelFormModel.Sizes = ''
    const assignedSizeScale = userStore.sizeScales[val]
    if (assignedSizeScale != null) {
      modelFormModel.Sizes = assignedSizeScale.Sizes
    }
  }

  const dataForValidation = Object.assign({}, props.targetedArticles && props.targetedArticles.length ? props.targetedArticles[0] : {}, modelFormModel)
  validateAndRemoveInvalidAttributeValues(refVisibleAttributes, modelFormModel, dataForValidation, attribute.SystemName)
}

function handleFormChange(attribute) {
  if (attribute === 'ModelCopies' && v$.value) {
    if (v$.value[attribute]) {
      v$.value[attribute].$touch()
    }
  }
}
function onCancel() {
  emit('cancel')
}

async function nextStep() {
  if (!await v$.value.$validate()) {
    errorMessage.value = t('validations.formInvalid')
    return
  }
  emit('next', { modelFormModel, modelArticlesAndPeriodsFormModel: undefined })
}
</script>
